import Head from 'next/head'

import { defaultSeoData } from '../../constants/DefaultSeoData'
import { ISEOData } from '../../utils/seo-helpers'

export const NextHeader = (seoData: ISEOData) => {
	const defaultOgValues = defaultSeoData.is
	const ogTitle =
		seoData.title.ogTitle != undefined && seoData.title.ogTitle.length > 0
			? seoData.title.ogTitle
			: defaultOgValues.title
	const ogDescription =
		seoData.ogDescription != undefined && seoData.ogDescription.length > 0
			? seoData.ogDescription
			: defaultOgValues.description

	const ogImage = seoData.ogImage != undefined && seoData.ogImage.length > 0 ? seoData.ogImage : defaultOgValues.image
	const pageTitle = seoData.title.metaTitle ? seoData.title.metaTitle : seoData.title.pageTitle
	return (
		<Head>
			<title>{pageTitle || defaultSeoData.is.title}</title>
			<meta charSet='utf-8' />
			<meta
				name='viewport'
				content='initial-scale=1.0, width=device-width'
			/>
			{seoData.noIndex && (
				<meta
					name='robots'
					content='noindex'
				/>
			)}
			{seoData.metaDescription && (
				<meta
					name='description'
					content={seoData.metaDescription}
				/>
			)}
			<meta
				property='og:title'
				content={ogTitle}
			/>
			<meta
				property='og:description'
				content={ogDescription}
			/>
			<meta
				property='og:image'
				content={ogImage}
			/>

			{/* Twitter */}
			<meta
				property='twitter:card'
				content='summary_large_image'
			/>
			<meta
				property='twitter:title'
				content={ogTitle}
			/>
			<meta
				property='twitter:description'
				content={ogDescription}
			/>
			<meta
				property='twitter:image'
				content={ogImage}
			/>
			<meta
				name='theme-color'
				content='var(--deep-blue)'
			/>
			<meta
				name='msapplication-TileColor'
				content='var(--deep-blue)'
			/>
		</Head>
	)
}

export default NextHeader
