import * as PrismicTypes from '@prismicio/types'

import FrontPage from '../modules/FrontPage'
import { Language } from '../types/Types'
import { getAllDocumentsByType, getLayoutData, getSingletonPageByType } from '../utils/prismic/data-helpers'
import { FrontpageDocument, NewsarticleDocument } from '../../prismicio-types'

const Index: React.FC<{ frontPage: FrontpageDocument; newsArticles: NewsarticleDocument[] }> = ({
	frontPage,
	newsArticles,
}) => {
	return (
		<FrontPage
			{...frontPage}
			newsArticlesData={newsArticles}
		/>
	)
}

export const getStaticProps = async ({ previewData, locale }: { previewData: PrismicTypes.Ref; locale: Language }) => {
	const { ref } = previewData || {}

	const [frontPage, layoutData, newsArticles] = await Promise.all([
		getSingletonPageByType('frontpage', ref, locale),
		getLayoutData(ref, locale),
		getAllDocumentsByType('newsarticle', ref, locale, {
			orderings: {
				field: 'my.newsarticle.published_date',
				direction: 'desc',
			},
		}),
	])

	return {
		props: {
			frontPage,
			layoutData: { ...layoutData },
			newsArticles,
		},
	}
}
export default Index
