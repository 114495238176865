import { AllDocumentTypes, FrontpageDocument, NewsarticleDocument, PageDocument } from '../../prismicio-types'

export interface IHreflang {
	url: string
}

interface ISEOLinks {
	canonical: string
	hreflangs?: IHreflang[]
}

export interface ISEOData {
	title: {
		metaTitle?: string
		ogTitle?: string
		pageTitle?: string
	}
	metaDescription?: string
	links?: ISEOLinks
	noIndex: boolean
	ogDescription?: string
	ogImage?: string
}

const buildFrontpageSeoLinks = (): ISEOLinks => {
	return { hreflangs: [], canonical: '/' }
}

const buildPageSeoLinks = (currentPage: { uid: string }): ISEOLinks => {
	return { hreflangs: [], canonical: `/${currentPage.uid}` }
}
/*
const buildSubpageSeoLinks = (currentPage: { uid: string; data: { link_to_parent: { uid?: string } } }): ISEOLinks => {
  const { data, uid } = currentPage
  return { hreflangs: [], canonical: `/${data.link_to_parent.uid}/${uid}` }
}
*/

//TODO provide sensible default values for SEOData
export const getPageSEOData = (page: PageDocument | FrontpageDocument): ISEOData => {
	return {
		title: {
			metaTitle: page.data.meta_title ?? '',
			ogTitle: page.data.og_title ?? '',
			pageTitle: page.data.title ?? '',
		},
		metaDescription: page.data.meta_description ?? '',
		links: 'uid' in page ? buildPageSeoLinks(page as PageDocument) : { hreflangs: [], canonical: `/` },
		noIndex: page.data.no_index,
		ogImage: page.data.og_image?.url ?? '',
		ogDescription: page.data.og_description ?? '',
	}
}
/*
export const getSubPageSEOData = (subpage: ISubPage.IProps): ISEOData => {
  return {
	metaTitle: subpage.data.meta_title,
	metaDescription: subpage.data.meta_description,
	links: buildSubpageSeoLinks({
	  ...subpage,
	  data: {
		...subpage.data,
		link_to_parent: 'link_to_parent' in subpage.data ? subpage.data.link_to_parent : { uid: routingPaths.news },
	  },
	}), //I reckon we need a better solution to this
	noIndex: subpage.data.noIndex,
	ogImage: subpage.data.og_image?.url,
	ogTitle: subpage.data.og_title,
	ogDescription: subpage.data.og_description,
  }
}
*/

export const getNewsArticleSEOData = (newsArticlePage: NewsarticleDocument): ISEOData => {
	return {
		title: {
			metaTitle: newsArticlePage.data.meta_title || newsArticlePage.data.title || '',
			ogTitle: newsArticlePage.data.meta_title || newsArticlePage.data.title || '',
		},
		metaDescription: newsArticlePage.data.meta_description || '',
		noIndex: false, //newsArticlePage.data.no_index || false,
		ogImage: newsArticlePage.data.preview_image?.url ?? '',
		ogDescription: newsArticlePage.data.meta_description || newsArticlePage.data.meta_description || '',
	}
}
export const getFrontPageSEOData = (): ISEOData => {
	return {
		links: buildFrontpageSeoLinks(),
		noIndex: false,
		title: {},
	}
}

interface ISchema {
	'@context': string
	'@type': string
	mainEntity: {
		'@type': string
		name: string
		acceptedAnswer: {
			'@type': string
			text: string
		}
	}[]
}

const BASE_SCHEMA: ISchema = {
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	mainEntity: [],
}

const mergeRichText = (answer) => {
	const merged = answer.reduce((acc, item) => {
		if (item.type === 'paragraph' || (typeof item.inclues === 'function' && item.includes('heading'))) {
			return acc + item.text
		}
		if (item.type === 'hyperlink') {
			return acc + item.data.url
		}
	}, '')

	return merged
}

export const generateSchema = (questions): ISchema => {
	const schema = BASE_SCHEMA
	if (!Array.isArray(questions) || !questions.length) return schema

	schema.mainEntity = questions.map((question) => ({
		'@type': 'Question',
		name: question.question,
		acceptedAnswer: {
			'@type': 'Answer',
			text: Array.isArray(question?.answer) ? mergeRichText(question.answer) : question.answer,
		},
	}))
	return schema
}
