// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic'

export const components = {
	card: dynamic(() => import('./CardsRow')),
	contact_us: dynamic(() => import('./ContactUs')),
	cta_slice: dynamic(() => import('./CtaSlice')),
	full_width_rich_text_card: dynamic(() => import('./RichTextCardSlice')),
	fullwidth_video: dynamic(() => import('./FullwidthVideo')),
	link_buttons_card: dynamic(() => import('./LinkButtonsCard')),
	link_cards: dynamic(() => import('./LinkCards')),
	link_list: dynamic(() => import('./LinkList')),
	navigation_header_item: dynamic(() => import('./NavigationHeaderItem')),
	news_slice: dynamic(() => import('./NewsSlice')),
	page_title: dynamic(() => import('./PageTitle')),
	people_card_slice: dynamic(() => import('./PersonCardSlice')),
	product_highlight_slice: dynamic(() => import('./ProductHighlightSlice')),
	qn_a: dynamic(() => import('./QnA')),
	request_offer_form: dynamic(() => import('./RequestOfferForm')),
	spacer: dynamic(() => import('./Spacer')),
	table_slice: dynamic(() => import('./TableSlice')),
	two_box_square_slice: dynamic(() => import('./TwoBoxSquareSlice')),
	web_solution_slice: dynamic(() => import('./WebSolutionSlice')),
}
