import React from 'react'
import { SliceZone } from '@prismicio/react'

import { components } from '../../../slices'
import NextHeader from '../../components/NextHeader'
import { getPageSEOData } from '../../utils/seo-helpers'
import { FrontpageDocument, NewsarticleDocument } from '../../../prismicio-types'

const FrontPage = (props: FrontpageDocument & { newsArticlesData: NewsarticleDocument[] }) => {
	const [isSearchOpen, setIsSearchOpen] = React.useState<boolean>(false)

	return (
		<>
			<NextHeader {...getPageSEOData(props)} />

			<SliceZone
				context={{ newsArticlesData: props.newsArticlesData }}
				slices={props.data.slices}
				components={components as any}
			/>
		</>
	)
}

export default FrontPage
